<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-users-list')"
        @delete="helperDeleteItem(`/users/${currentItem.id}`, null, 'apps-users-list')"
        @save="updateItem"
        @refresh="getItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
          @submit.stop.prevent="handleSubmit(updateItem)" @reset.prevent="resetForm"
      >
        <b-card>
          <!-- Name -->
          <validation-provider
              #default="validationContext"
              :name="$t('Name')"
              rules="required"
          >
            <b-form-group label="Name">
              <b-form-input
                  id="name"
                  v-model="currentItem.name"
                  autofocus
                  trim
                  :placeholder="$t('Full Name')"
              />

              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger"
              >{{ $t(validation.name[0]) }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
          >
            <b-form-group :label="$t('Email')">
              <b-form-input
                  id="email"
                  :placeholder="$t('Email')"
                  v-model="currentItem.email"
                  trim
              />

              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('email')" class="text-danger"
              >{{ $t(validation.email[0]) }}</small>
            </b-form-group>
          </validation-provider>
        </b-card>
        <b-card :title="$t('Login')">
          <b-row>
            <!-- password -->
            <b-col cols="6">
              <b-form-group :label="$t('Password')">
                <validation-provider
                    #default="{ errors }"
                    :label="$t('Password')"
                    :name="$t('Password')"
                    vid="Password"
                    rules="min:8|password"
                    class="mt-3"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                        v-model="currentItem.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                  <small v-if="validate && Object.keys(validation).includes('password')" class="text-danger"
                  >{{ $t(validation.password[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- confirm password -->
            <b-col cols="6">
              <b-form-group :label="$t('Confirm Password')">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Confirm Password')"
                    rules="confirmed:Password"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                        v-model="currentItem.password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordconfirmFieldType"
                        :placeholder="$t('Confirm Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordconfirmToggleIcon"
                          @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                  <small v-if="validate && Object.keys(validation).includes('password_confirmation')"
                         class="text-danger"
                  >{{ $t(validation.password_confirmation[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                type="submit"
            >
              {{ $t('Save') }}
            </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { required, alphaNum, email } from '@validations'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { BCardActions } from '@core/components/b-card-actions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordconfirmToggleIcon() {
      return this.passwordconfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  data() {
    return {
      currentItem: {
        id: '',
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      loaded: true,
      validation: {},
      validate: false,
    }
  },

  methods: {
    getItem() {
      this.helperGetItem(`/users/${this.$route.params.userid}`)
    },
    updateItem() {
      this.loaded = false
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/users/${this.$route.params.userid}`,
                  this.currentItem,
                  function () {
                  },
                  function(error) {
                  }
              )
            }
          })
    },
    deleteItem() {
      this.helperDeleteItem(
          `/users/${this.$route.params.userid}`,
          this.currentItem,
          'apps-users-list'
      )
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    getFullname() {
      return (this.currentItem.firstname ?? this.currentItem.firstname) + ' ' + this.currentItem.name
    }

  },

  mounted() {
    this.getItem()
  },
}
</script>

<style>
</style>
